@import '../../../src/ui/style/colors';
@import '../../../src/ui/style/typography';

@mixin planFeaturesExceededWarningText {
  @include fontBold;

  display: inline-block;
  vertical-align: middle;
  padding: 0.25rem 1rem 0.25rem 1rem;
  margin-left: 12px;
  border-radius: 6px;
  background-color: rgba($color-background-mid, 0.2);
  border: none;
  transition: background-color 100ms;
  color: #ffffff;
  cursor: pointer;
}

.mastheadButton {
  @include planFeaturesExceededWarningText;

  &:hover {
    border: none;
    background-color: rgba($color-background-mid, 0.6);
    color: #ffffff !important; // overwrite global css
  }
}

.mastheadText {
  @include planFeaturesExceededWarningText;
}

.planFeaturesExceededModal {
  max-width: 525px;

  p {
    margin-bottom: 1rem;
  }

  ul {
    list-style: inside;
    margin-bottom: 1rem;
    margin-left: 1.25rem;
  }

  h3 {
    text-align: center;
  }

  a {
    border-bottom: none !important;
    color: $color-black-100;
    cursor: pointer;
    text-decoration: underline;

    &:hover {
      color: $color-black-100 !important;
    }
  }
}

.chat {
  display: inline-block;
  margin-left: 5px;
}

.bold {
  @include fontBold;
}
