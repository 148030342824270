@import '../../../../../src/ui/style/colors';

.dialog {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  background: #fff;
  border: solid 2px $color-black-100;
  border-radius: 6px;
  width: 450px;
  padding: 20px 40px;
  margin-inline: 0;
}

.description {
  text-align: center;
}

.directionControls {
  display: flex;
  gap: 1rem;
  margin-bottom: 1rem;
}

.buttonsContainer {
  display: flex;
  gap: 1rem;
}
