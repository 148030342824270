@import '../style/colors';
@import '../style/typography';

.upgradeBadge {
  @include fontBold;
  background-color: $color-geckoboard-mint;
  padding: 2px 6px;
  border-radius: 2px;
  color: $color-black-100;
  font-size: 0.63rem;
  text-transform: uppercase;
  white-space: nowrap;

  display: flex;
  align-items: center;
  gap: 3px;

  transition: background-color 0.2s;

  &:hover {
    background-color: darken($color-geckoboard-mint, 10%);
    color: $color-black-100 !important;
  }

  &:visited {
    color: $color-black-100 !important;
  }
}

.upgradeBadgeIcon {
  font-size: 0.75rem;
}
