.aggregates {
  min-width: 114px;
}

.dropdown {
  width: 100%;
  overflow: hidden;
}

.proBadge {
  display: flex;
  gap: 0.5rem;
}
