@import '../../../../../src/ui/style/colors';

.Form {
  display: flex;
  padding: 0 0 1rem;
  flex-wrap: wrap;
}

.FormField {
  flex-grow: 1;
  max-width: 60%;
}

.FormOperator {
  margin-left: 22px;
  width: 142px;
}

.FormOperand {
  width: 100%;
}

.FormHorizontal {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.LightText {
  color: $color-black-40;
}
