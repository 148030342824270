@import '../../src/ui/style/colors';
@import '../../src/ui/style/typography';

.DashboardSidebar {
  height: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  background: #fff;
  display: flex;
  flex-direction: column;
}

.DashboardSidebar__Header {
  padding: 0 1rem 0 1rem;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dfe4ee;
  min-height: 48px;

  .DashboardSidebar__Collapsed & {
    padding: 0.75rem;
  }
}

.DashboardSidebar__Heading {
  @include fontBold;
  font-size: 1rem;
  line-height: 1.5rem;
}

.DashboardSidebar__Collapse {
  font-size: 1.1rem;
  background: none;
  border: none;
  appearance: none;
  cursor: pointer;

  &:focus {
    transform: scale(1.1);
    outline: 1px dotted $color-black-100;
  }

  &:active {
    transform: none;
    outline: none;
  }
}

.DashboardsList {
  list-style: none;
  padding: 0;
  margin: 0;
  overflow-y: scroll;
  border-bottom: 1px solid #dfe4ee;
}

.DashboardsList__Item {
  margin: 0;
  padding: 0;
  position: relative;

  &:hover {
    .editTitleIcon {
      display: block;
    }

    .DashboardsList__Link__active {
      padding: 0.75rem 2.4rem 0.75rem 1.85rem;
    }
  }
}

.DashboardsList__Link {
  color: $color-black-100;
  position: relative;
  font-size: 1rem;
  line-height: 24px;
  display: block;
  padding: 0.75rem 1rem 0.75rem 1.85rem;
  height: 26px;
  max-height: 51px;
  display: flex;
  align-items: center;

  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:focus {
    background: #dfe4ee;
    outline: none;
  }

  &:hover,
  &:visited:hover {
    background: #dfe4ee;
    color: $color-black-100;
  }
}

.DashboardsList__Link__active {
  background: #dfe4ee;
  @include fontBold;

  textarea {
    color: $color-grey-100;
  }

  &:before {
    @include fontBold;
    position: absolute;
    content: '';
    top: 50%;
    left: 0;
    width: 6px;
    height: 33px;
    background: $color-black-100;
    transform: translateY(-50%);
    border-radius: 0 6px 6px 0;
  }
}

.editTitleIcon {
  display: none;
  position: absolute;
  right: 0.7rem;
  top: 50%;
  transform: translateY(-50%);
  color: $color-grey-100;
  background: none;
  border: none;
  cursor: pointer;
}

.AddDashboardButton {
  appearance: none;
  @include fontBold;
  background: none;
  border: none;
  font-size: 1rem;
  line-height: 1.625rem;
  text-align: left;
  padding: 1rem 1rem 1rem 1.85rem;
  height: auto;
  cursor: pointer;
  display: flex;
  align-items: center;

  &:focus {
    outline: none;
    text-decoration: underline;
    box-shadow: none;
  }

  &:hover:not(:disabled) {
    opacity: 0.75;
    background-color: transparent;
  }

  &:focus .AddDashboardButton__Text {
    display: block;
    margin-right: 8px;
  }
}

.AddDashboardButton__Icon {
  display: block;
  margin-right: 8px;
}
